import MainPage from './components/MainPage.vue'
import Installation from './components/Installation.vue'
import Usage from './components/Usage.vue'

const routes = [
  { path: '/', component: MainPage },
  { path: '/installation', component: Installation },
  { path: '/usage', component: Usage }
]

export default routes
