<template>
  <v-app>
    <v-toolbar app flat dark>
              <v-icon color="green">extension</v-icon>
      <v-toolbar-title class="headline text-uppercase">
        <span>PRODUCT PHOTOS</span>
        <span class="font-weight-light"> / EXCEL ADD-IN</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
      color="warning"
      href="https://gitlab.com/filipe.esteves/product-photos-excel-add-in/-/raw/master/ProductPhotos.xla?inline=false"
      target="_blank"
      @click="download()"
      >
      <span class="mr-2">Download</span>
      <v-icon right dark>get_app</v-icon>
    </v-btn>
  </v-toolbar>
  <v-content>
    <v-tabs
    color="blue-grey lighten-3
"
    align-with-title
    >
    <v-tab to="/">
      Home
    </v-tab>
    <v-tab to="/Installation">
      Installation Instructions
    </v-tab>
    <v-tab to="/Usage">
      How to Use
    </v-tab>
  </v-tabs>
    <router-view />
  </v-content>
  <!-- <v-footer class="pa-3">
    <v-spacer></v-spacer>
    <div>Filipe Esteves &copy; {{ new Date().getFullYear() }}</div>
  </v-footer> -->
  <Footer/>
</v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld'
// import MainPage from './components/MainPage.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
    // MainPage,
    Footer
  },
  data () {
    return {
      //
    }
  },
  methods: {
    download: function() {
      this.$ga.event('user-action', 'click', 'download-link', 1)
    }
  }
}
</script>
