<template>
  <v-card flat>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12 sm6 offset-sm3 mt-2>
          <v-card flat>
            <!-- <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
            height="200px"
          >
            </v-img>-->

            <v-card-title primary-title>
              <div>
                <div class="headline">Want to continue this project?</div>
                <div class="grey--text subtitle">5th November 2020</div>
                <div>
                  As <b>I have decided to leave PVH</b> and since this project has gained significant relevance within the organization (proof that is something useful and much needed), <b>I am looking for the right person to continue this project</b>.
                  <br/>
                  If you're interested, please reaach out to my email address at the bottom of this page and let's have a chat. :)
                  <br/>
                  <br/>
                  To all my PVH colleagues reading this, it's been fun and I'm sure we will see each other around!
                  <br/>
                  <br/>Take care,
                  <br/>Filipe

                </div>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 offset-sm3 mt-2>
          <v-card flat>
            <!-- <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
            height="200px"
          >
            </v-img>-->

            <v-card-title primary-title>
              <div>
                <div class="headline">v3.2 Change log</div>
                <div class="grey--text subtitle">11th May 2019</div>
                <div>
                  <ul>
                    <li>Updated picture naming convention for DS assets.</li>
                    <li>Added link reference to add-in for easy reference.</li>
                  </ul>
                </div>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 offset-sm3>
          <v-card flat>
            <v-card-title primary-title>
              <div>
                <div class="headline">v3.1 Change log</div>
                <div class="grey--text subtitle">20th March 2019</div>
                <div>
                  <ul>
                    <li>Added MacOS compatiblity.</li>
                    <li>Performance on MacOS is a bit slower when compared to Windows due to less frameworks available within Excel.</li>
                  </ul>
                </div>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 offset-sm3>
          <v-card flat>
            <v-card-title primary-title>
              <div>
                <div class="headline">v3.0 Change log</div>
                <div class="grey--text subtitle">28th July 2018</div>
                <div>
                  <ul>
                    <li>Adjusted getImage to also retrieve images for TH styles.</li>
                    <li>If image not found, the script also looks into the Digital Showroom S7 account (CK and TH).</li>
                    <li>Increased grabPicBig picture size.</li>
                    <li>Pictures are now placed to the left of the style/combi number.</li>
                    <li>Script now works with styles typed in lowercase.</li>
                    <li>Removed vertical align formatting</li>
                    <li>Addin no longer freezes excel when processing large number of requests</li>
                    <li>Added modal dialog to show progress</li>
                  </ul>
                </div>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
  <!-- <div id="main">
    <div id="title">
      Hi there!
    </div>
    <div id="subtitle">
      Updated 11th of May 2019.<br/>
    </div>
    <div id="changelog">
      <div>
        v3.1
      </div>
      <div>
      <ul>
        <li>
          Updated ccc picture naming convention for DS assets.
        </li>
        <li>
          Added link reference to add-in for easy reference.
        </li>
      </ul>
      </div>

    </div>
  </div>-->
</template>

<script>
export default {
  name: "MainPage",
  // props: {
  //   msg: String
  // }
};
</script>

<style>
#main {
  margin: auto;
}
#title {
  font-family: "Baloo Bhai", cursive;
  font-size: 300%;
}
.subtitle {
  /* font-family: 'Source Sans Pro', sans-serif; */
  /* font-size: 100%; */
  margin-bottom: 20px;
}
#changelog {
  font-family: "Source Sans Pro", sans-serif;
  margin: auto;
}
</style>
