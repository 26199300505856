import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
// import store from './store'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-analytics'


import store from './store'
import routes from './routes'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueAnalytics, {
  id: 'UA-6306551-7',
  router
})

const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  store,
  render: h => h(App),
  router
}).$mount('#app')
