<template>
  <v-footer
    dark
    height="auto"
  >
    <v-card
      flat
      tile
      class="indigo lighten-1 white--text text-xs-center"
      min-width="100%"
    >
      <v-card-text>
        <v-btn
          href="https://twitter.com/iamflipe"
          target="_blank"
          class="mx-3 white--text"
          icon
        >
          <v-icon size="24px">fab fa-twitter</v-icon>
        </v-btn>
        <v-btn
        href="https://www.linkedin.com/in/filipeesteves"
        target="_blank"
          class="mx-3 white--text"
          icon
        >
          <v-icon size="24px">fab fa-linkedin</v-icon>
        </v-btn>
        <v-btn
        href="mailto:me@filipeesteves.pt"
        target="_blank"
          class="mx-3 white--text"
          icon
        >
          <v-icon size="24px">fas fa-envelope</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        This plugin is developed out of my personal initiative and time. Its aim is to help PVH associates who deal with Excel files containing (sometimes large amounts of) style numbers and <br />often require the pictures of those products to better do their work. <br />
        If you encounter any issues or have any suggestions, please feel free to reach out using any of the above channels.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        &copy; {{ new Date().getFullYear() }} — <strong>Filipe Esteves</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
  }
</script>
